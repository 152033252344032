// Ref: https://loading.io/css/
.lds-ring {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    width: 64px;
    height: 64px;
    border-radius: 50%;

    &.small {
        width: 32px;
        height: 32px;

        &::before,
        &::after {
            border-width: 3px;
        }
    }

    &::before,
    &::after {
        content: "";
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        border: 6px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    &.dark {
        &::before,
        &::after {
            border-color: #CCC transparent transparent transparent;
        }
    }

    &::before {
        animation-delay: -.3s;
    }

    &::after {
        animation-delay: -.18s;
    }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
